import React from "react"

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"
import Link from "gatsby-link"

import "./dataprivacy.scss"

const icons = {
  hero: require("../images/gdpr/hero.png"),
  security: require("../images/gdpr/security.png"),
  technology: require("../images/gdpr/technology.png"),
  control: require("../images/gdpr/control.png"),
  gdpr: require("../images/gdpr/gdpr.png"),
  transparency: require("../images/gdpr/transparency.png"),
  policies: require("../images/gdpr/policies.png"),
}

const GDPR = () => {
  return (
    <Layout>
      <SEO title="Data Privacy" />
      <div className="hero">
        <Container>
          <Row>
            <Col xs={{ order: 12, span: 12 }} sm={{ order: 1, span: 6 }}>
              <div className="flexCenter">
                <h1>
                  <span className="greenText">Data privacy</span> and{" "}
                  <span className="greenText">compliance</span> at SHAPE
                </h1>
                <p>
                  At SHAPE, we have your trust at the heart of our service. We
                  protect your data and align with data privacy laws at every
                  step of our service.
                </p>
              </div>
            </Col>
            <Col xs={{ order: 1, span: 12 }} sm={{ order: 12, span: 6 }}>
              <div className="imgMarginSmall">
                <img src={icons.hero} alt="Data Privacy" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="content">
        <section>
          <Container>
            <div className="marginBottom">
              <h3>How SHAPE protects you</h3>
              <Row>
                <Col sm={5}>
                  <div className="imgMargin">
                    <img src={icons.security} alt="Security" />
                  </div>
                </Col>
                <Col sm={7}>
                  <h4 className="greenText">Security</h4>
                  <p>
                    We have carefully designed information security practices
                    and policies that ensure data security at all times.
                    Microsoft Azure cloud technology provides a secure
                    foundation across physical, infrastructure and operational
                    systems. Only relevant personnel in SHAPE have access to
                    customer data ensuring we act as a responsible data
                    processor. Our standard operating procedures include regular
                    personnel training, ongoing assessments and regular audits
                    alongside robust documentation controls to prevent a data
                    breach.
                  </p>
                </Col>
              </Row>
            </div>
            <div className="marginBottom">
              <Row>
                <Col xs={{ order: 12, span: 12 }} sm={{ order: 1, span: 7 }}>
                  <h4 className="greenText">Technology</h4>
                  <p>
                    SHAPE employs state-of-the-art technology, allowing
                    employees to confidently and securely answer survey
                    questions and confidentially view their results. Microsoft
                    Azure cloud technology ensures access to the latest,
                    enterprise-grade security, practices and performance. For
                    additional security, we employ data pseudonymisation and
                    encryption technologies – both in transit and at rest. We
                    fully document and control the use of algorithms and employ
                    extensive test methodologies to ensure data accuracy and
                    security.
                  </p>
                </Col>
                <Col xs={{ order: 1, span: 12 }} sm={{ order: 12, span: 5 }}>
                  <div className="imgMargin">
                    <img src={icons.technology} alt="Technology" />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="marginBottom">
              <Row>
                <Col sm={5}>
                  <div className="imgMargin">
                    <img src={icons.control} alt="Full Control" />
                  </div>
                </Col>
                <Col sm={7}>
                  <h4 className="greenText">Full Control</h4>
                  <p>
                    SHAPE operates in a way that gives its users complete
                    control. For our diverse reader populations, we operate a
                    clear language policy. We empower you with rich knowledge
                    and documentation to safely engage your employees about
                    SHAPE. You and your employees always have the right to view
                    and rectify data under Article 15 and 16 of GDPR (General
                    Data Protection Legislation). We offer complete access to
                    updated legal and contractual documents, including our{" "}
                    <Link to="/privacy">Privacy Policy</Link> and Master
                    Services Agreement.
                  </p>
                </Col>
              </Row>
            </div>
            <div className="marginBottom">
              <Row>
                <Col xs={{ order: 12, span: 12 }} sm={{ order: 1, span: 7 }}>
                  <h4 className="greenText">GDPR Compliance</h4>
                  <p>
                    Our goal remains ongoing compliance with GDPR, which we
                    actively understand and align with. All data is owned by the
                    employees who provide it, so they have complete control over
                    what happens to it. SHAPE ensures continuous data
                    anonymisation and collects explicit consent where options
                    exist to waive anonymity. We operate clear and defined
                    processes for data management, including account and data
                    deletion under rules for the transfer of data
                    internationally.
                  </p>
                </Col>
                <Col xs={{ order: 1, span: 12 }} sm={{ order: 12, span: 5 }}>
                  <div className="imgMargin">
                    <img src={icons.gdpr} alt="GDPR Compliance" />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="marginBottom">
              <Row>
                <Col sm={5}>
                  <div className="imgMargin">
                    <img
                      src={icons.transparency}
                      alt="Transparency and consent"
                    />
                  </div>
                </Col>
                <Col sm={7}>
                  <h4 className="greenText">Transparency and consent</h4>
                  <p>
                    SHAPE's communicates openly. We explicitly ask for consent
                    and maintain records of it. We never share data without
                    permission. Our <Link to="/privacy">Privacy Policy</Link>{" "}
                    are accessible at all times with a clear history of updates
                    applied. Requests for data deletion are always honoured as
                    part of the right to be forgotten. We operate an updated
                    Frequency Asked Questions section to empower improved
                    understanding of our service and the rights of our
                    customers.
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col xs={{ order: 12, span: 12 }} sm={{ order: 1, span: 7 }}>
                  <h4 className="greenText">Policies</h4>
                  <p>
                    SHAPE's policies are in line with the UK Information
                    Commissioner's Office (ICO), and regular privacy assessments
                    are performed as part of our product development and audit
                    processes. We also carry out regular data, system and
                    security audits. We classify all data, which allows us to
                    manage sensitive and personal data. All our business
                    practices, including staff training, are documented in the
                    form of detailed Standard Operating Procedures. We
                    continuously review international regulations to ensure
                    further compliance with country laws where we operate.
                  </p>
                </Col>
                <Col xs={{ order: 1, span: 12 }} sm={{ order: 12, span: 5 }}>
                  <div className="imgMargin">
                    <img src={icons.policies} alt="Policies" />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default GDPR
